import { createContext } from 'react';

interface IAircraftContext {
    selectedAircraftId: number | undefined;
    setAircraft: (aircraftId: number) => void;
}

const defaultState = {
    selectedAircraftId: undefined,
    setAircraft: () => {
        return;
    }
};

export const AircraftContext = createContext<IAircraftContext>(defaultState);
