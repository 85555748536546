import * as React from 'react';

function ArrowCircleIcon() {
    return (
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M3.4998 6.19661H4.69313C4.99313 6.19661 5.1398 6.55661 4.92647 6.76328L3.06646 8.62328C2.93313 8.75661 2.72646 8.75661 2.59313 8.62328L0.733131 6.76328C0.526464 6.55661 0.673131 6.19661 0.973131 6.19661H2.16646C2.16646 3.24995 4.55313 0.863281 7.4998 0.863281C8.19313 0.863281 8.8598 0.996615 9.46646 1.24328C9.91313 1.42328 10.0331 1.99661 9.69313 2.33661C9.51313 2.51661 9.2398 2.58995 8.9998 2.48995C8.5398 2.29661 8.02647 2.19661 7.4998 2.19661C5.29313 2.19661 3.4998 3.98995 3.4998 6.19661ZM5.30646 10.0566C5.48646 9.87661 5.76646 9.80328 5.9998 9.90328C6.46646 10.0899 6.97313 10.1966 7.4998 10.1966C9.70646 10.1966 11.4998 8.40328 11.4998 6.19661H10.3065C10.0065 6.19661 9.8598 5.83661 10.0731 5.62995L11.9331 3.76995C12.0665 3.63661 12.2731 3.63661 12.4065 3.76995L14.2665 5.62995C14.4731 5.83661 14.3265 6.19661 14.0331 6.19661H12.8331C12.8331 9.14328 10.4465 11.5299 7.4998 11.5299C6.80646 11.5299 6.1398 11.3966 5.53313 11.1499C5.08646 10.9699 4.96646 10.3966 5.30646 10.0566Z"
                fill="white"
            />
        </svg>
    );
}

export default ArrowCircleIcon;
