import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import '../../i18n/config';
import log from '../../logger';
import { AuthService } from 'react-oauth2-pkce';
import { Dialog, DialogActions, DialogContent, DialogHeader, Button as AirbusButton } from '@airbus/components-react';
import { useSelector } from 'react-redux';
import { selectTokenExpiryDate } from '../../redux/app/features/credentialsSlice';

const TWO_HOURS_AND_A_HALF = 9000000;

type Props = {
    authService: AuthService;
};

const SessionDialog = ({ authService }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [isTimerSet, setIsTimerSet] = useState<boolean>(false);

    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const isMoreThan4HoursExpired = (): boolean => {
        let isLongTermExpired = false;
        if (tokenExpiryDate !== undefined) {
            const date = Date.now();
            isLongTermExpired = tokenExpiryDate - date < -TWO_HOURS_AND_A_HALF;
        }
        return isLongTermExpired;
    };

    const relog = (): void => {
        setOpen(false);
        authService.logout();
        authService.authorize();
    };

    const getDelay = (): number | undefined => {
        if (tokenExpiryDate) {
            const date = Date.now();
            log.debug('[AUTH] token expires at: ', new Date(date).toString());
            return tokenExpiryDate - date;
        }
        return undefined;
    };

    const displayTokenDialog = () => {
        if (isMoreThan4HoursExpired()) {
            relog();
        } else if (!isTimerSet && getDelay() && authService.isAuthenticated()) {
            setIsTimerSet(true);
            setTimeout(() => setOpen(true), getDelay());
        }
    };

    useEffect(() => {
        displayTokenDialog();
    });

    return (
        <Dialog open={open} onClose={() => relog()}>
            <DialogHeader>{i18n.t('sessionDialog.title')}</DialogHeader>
            <DialogContent>
                <p>{i18n.t('sessionDialog.content')}</p>
            </DialogContent>
            <DialogActions>
                <AirbusButton variant="primary" onClick={() => relog()}>
                    {i18n.t('sessionDialog.button')}
                </AirbusButton>
            </DialogActions>
        </Dialog>
    );
};

export default SessionDialog;
