import axios, { AxiosPromise } from 'axios';
import { apiUrl } from '../../utils/ApplicationUtils';

export function getEnhancedApplications(apiToken: string | undefined, filterByCompany: boolean): AxiosPromise<any> {
    const url = apiUrl + '/app_enhanced';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        },
        params: {
            filterByCompany
        }
    };

    return axios.get(url, headers);
}
