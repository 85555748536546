import i18n from 'i18next';
import { ICurrentConfigHeadCell } from '../components/aircraft-view/types';
import '../i18n/config';

export const PALETTE = {
    CHECK_GREEN: '#2ED19B',
    RED_ERROR: '#E93255'
};

export const CURRENT_CONFIG_HEAD_CELLS: ICurrentConfigHeadCell[] = [
    {
        id: 'name',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell1')}`
    },
    {
        id: 'domain',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell2')}`
    },
    {
        id: 'providerId',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell3')}`
    },
    {
        id: 'size',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell4')}`
    },
    {
        id: 'installed_on',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell5')}`
    },

    {
        id: 'status',
        label: `${i18n.t('aircraft.aircraftConfig.overview.currentConfig.headerCell6')}`
    }
];
