import { Aircraft, AircraftDataTable } from '../../models/Aircraft';

export function aircraftToDataTable(aircraft: Aircraft): AircraftDataTable {
    return {
        ...aircraft,
        id: aircraft.id.toString(),
        airlineCompany: aircraft.airlineCompany?.icao ?? '',
        responsibleCompanies: aircraft.responsibleCompanies
            ? aircraft.responsibleCompanies.reduce((prev: string, curr: { name: string }) => `${prev}, ${curr.name}`, '').replace(', ', '')
            : ''
    };
}
