/* eslint-disable max-len */
import * as React from 'react';

function TrashIcon() {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 2C14 1.44772 13.5523 1 13 1H10.5C9.85971 0.359711 8.99129 0 8.08579 0H5.91421C5.00871 0 4.14029 0.359711 3.5 1H1C0.447715 1 0 1.44772 0 2C0 2.55228 0.447715 3 1 3H13C13.5523 3 14 2.55228 14 2Z"
                fill="#063B9E"
            />
        </svg>
    );
}

export default TrashIcon;
