import React from 'react';
import { PermissionFindResponse } from '../../../models/PermissionModel';
import { useCustomQuery } from '../utils';
import { PermissionTable } from './PermissionsTable';

type Props = {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
    rolePermissions: number[] | undefined;
    onChangePermissions: (permissionIds: number[]) => void;
};
export const RolePermissionTable = ({ token, tokenExpiryDate, rolePermissions, onChangePermissions }: Props) => {
    const { data: permissionResponse } = useCustomQuery<PermissionFindResponse, any>(token, tokenExpiryDate, 'permission', true, false);

    return (
        <div className="role-permission-container">
            <span className="permissions-title">Permissions</span>
            <hr className="role-permission-divider"></hr>
            <div className="checkbox-group">
                {permissionResponse?.data?.length && (
                    <PermissionTable
                        selectedPermissions={rolePermissions}
                        options={permissionResponse?.data}
                        onChangePermissions={onChangePermissions}
                    />
                )}
                <div />
            </div>
        </div>
    );
};
