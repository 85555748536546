import React, { useState } from 'react';
import { Tooltip } from '@airbus/components-react';
import { VpnKey } from '@airbus/icons/react';
import i18n from 'i18next';

import classes from './CopyText.module.css';

type Props = {
    text: string | undefined;
};

const CopyText = ({ text }: Props) => {
    const [tooltipText, setTooltipText] = useState<string>(i18n.t('applications.table.copySignature'));

    return (
        <div className={classes.copy_text}>
            <Tooltip placement="top" label={tooltipText}>
                <VpnKey
                    className={classes.vpn_key}
                    onClick={() => {
                        const toCopy = text ?? '';
                        navigator.clipboard.writeText(JSON.stringify(toCopy));
                        setTooltipText(i18n.t('applications.table.copied'));
                    }}
                    onMouseLeave={() => {
                        setTooltipText(i18n.t('applications.table.copySignature'));
                    }}
                />
            </Tooltip>
        </div>
    );
};

export default CopyText;
