import axios from 'axios';

import { CurrentUser } from '../../models/CurrentUserModel';
import { Error200 } from '../../utils/ErrorUtils';
import { apiUrl } from '../../utils/ApplicationUtils';

export async function getCurrentUser(apiToken: string | undefined): Promise<CurrentUser & Error200> {
    const url = apiUrl + '/current-user';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        }
    };

    const res = await axios.get<CurrentUser & Error200>(url, headers);
    return res.data;
}
