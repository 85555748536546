import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableSortLabel } from '@airbus/components-react';
import { visuallyHidden } from '@mui/utils';
import { CurrentConfig } from '../../../../models/CurrentConfig';
import styles from './DataTable.module.css';
import { CURRENT_CONFIG_HEAD_CELLS } from '../../../../constants/CurrentConfig';
import { CurrentAppDisplayData, CurrentRowDisplayData, CustomSortDirection } from '../../types';
import DataRow from './DataRow';

type Props = {
    refreshAppList?: () => void;
    apps: CurrentConfig[];
};

const DataTable = ({ apps }: Props) => {
    const [order, setOrder] = useState<CustomSortDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof CurrentRowDisplayData>('name');

    const descendingComparator = (a: CurrentAppDisplayData, b: CurrentAppDisplayData, orderBy: keyof CurrentRowDisplayData) => {
        const paramA = a.mainApp[orderBy];
        const paramB = b.mainApp[orderBy];

        if (!paramA) {
            return 1;
        }
        if (!paramB) {
            return -1;
        }

        if (paramB < paramA) {
            return -1;
        }
        if (paramB > paramA) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order: CustomSortDirection, orderBy: keyof CurrentRowDisplayData) => {
        return order === 'desc'
            ? (a: CurrentAppDisplayData, b: CurrentAppDisplayData) => descendingComparator(a, b, orderBy)
            : (a: CurrentAppDisplayData, b: CurrentAppDisplayData) => -descendingComparator(a, b, orderBy);
    };

    const appDisplayInfos = apps.length
        ? apps.map((app: CurrentConfig) => {
              return {
                  mainApp: {
                      id: app.id,
                      name: app.name,
                      domain: '',
                      size: app.size,
                      installed_on: app.installed_on,
                      providerId: app.providerId,
                      status: app.status
                  },
                  appVersion: app.appComponents.map((appComponent) => ({
                      id: appComponent.id,
                      name: appComponent.name,
                      version: appComponent.version,
                      size: appComponent.size,
                      domain: appComponent.domain,
                      updated_at: appComponent.updated_at
                  }))
              };
          })
        : [];

    const handleRequestSort = (property: keyof CurrentRowDisplayData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div>
            {appDisplayInfos?.length && (
                <TableContainer className={styles.table_container} component={Paper}>
                    <Box sx={{ overflow: 'auto' }}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow
                                    className={styles.table_header}
                                    sx={{
                                        '& > *': { borderBottom: 'unset' },
                                        bgcolor: '#fafbfc'
                                    }}>
                                    {CURRENT_CONFIG_HEAD_CELLS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            sx={{ bgColor: '#fafbfc' }}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => {
                                                    handleRequestSort(headCell.id);
                                                }}>
                                                {headCell.label}
                                                {orderBy === headCell.id ?? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                )}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ bgColor: '#fafbfc' }}>
                                {appDisplayInfos?.length &&
                                    appDisplayInfos
                                        .slice()
                                        .sort(getComparator(order, orderBy))
                                        .map((app: CurrentAppDisplayData) => {
                                            return <DataRow key={app.mainApp.id} row={app} />;
                                        })}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            )}
        </div>
    );
};
export default DataTable;
