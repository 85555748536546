import React, { useState, useMemo } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { DataTable, Toggle } from '@airbus/components-react';

export const RoleTable = ({
    options,
    selectedRoles,
    onChangeRoles,
    setValidationDisable
}: {
    options: any;
    selectedRoles: number[] | undefined;
    onChangeRoles: (selectionIds: number[]) => void;
    setValidationDisable: (bool: boolean) => void;
}) => {
    const rolesIdsForGivenUser = selectedRoles?.filter((x: number) => !!x).sort();

    const [selectionModel, setSelectionModel] = useState<number[] | undefined>(rolesIdsForGivenUser);

    // Handle data
    useDeepCompareEffectNoCheck(() => {
        // eslint-disable-next-line
        if (JSON.stringify(rolesIdsForGivenUser?.sort()) === JSON.stringify(selectionModel?.sort())) {
            setValidationDisable(true);
        } else {
            setValidationDisable(false);
        }
    }, [selectionModel]);

    useDeepCompareEffectNoCheck(() => {
        if (options && rolesIdsForGivenUser) {
            const selectionIds = options
                ?.filter(({ id }: { id: number }) => rolesIdsForGivenUser.includes(id))
                .map((elt: { id: string }) => elt.id);

            setSelectionModel(selectionIds);
            onChangeRoles(selectionIds);
        } else {
            setSelectionModel([]);
            onChangeRoles([]);
        }
    }, [options, rolesIdsForGivenUser]);

    const columns: any[] = useMemo(() => {
        return [
            {
                header: 'id',
                id: 'id',
                minWidth: 30,
                width: 30,
                accessor: (row: any) => row.id,
                Cell: ({ value }: { value: number }) => <Toggle checked={!!selectionModel?.includes(value)} disabled={false} size="small" />
            },
            {
                header: 'labels',
                id: 'label',
                minWidth: 100,
                accessor: (row: any) => row.label,
                Cell: ({ value }: { value: string }) => <div className="header-cell">{value}</div>
            }
        ];
    }, [selectionModel?.length]);

    return (
        <DataTable
            columns={columns}
            data={options}
            defaultRowsPerPage={options.length}
            className={'permissionTable'}
            onRowClick={({ id }: any) => {
                let result: number[];
                if (selectionModel) {
                    if (!selectionModel.includes(id)) {
                        result = [...selectionModel, id];
                    } else {
                        result = [...selectionModel.filter((elt) => elt !== id)];
                    }
                    setSelectionModel(result);
                    onChangeRoles(result);
                }
            }}></DataTable>
    );
};
