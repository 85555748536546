import '../i18n/config';
import i18n from 'i18next';
import moment from 'moment';

// Display the date of an app Version ('createdAt' attribute in DB) with hour + am/pm and today/yesterday
export function prettyDate(date: Date | string): string {
    const yesterdaysDate = new Date().setDate(new Date().getDate() - 1);
    const today = moment(new Date()).format('D MMM YYYY');
    const yesterday = moment(yesterdaysDate).format('D MMM YYYY');
    const dbDate = moment(date).format('D MMM YYYY');

    if (date === '-') {
        return date;
    } else if (dbDate === today) {
        return `${i18n.t('date.today')} ${moment(date).format('h:mm a')}`;
    } else if (dbDate === yesterday) {
        return `${i18n.t('date.yesterday')} ${moment(date).format('h:mm a')}`;
    } else {
        return `${dbDate} ${i18n.t('date.at')} ${moment(date).format('h:mm a')}`;
    }
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T{0,1}\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

const isIsoDateString = (value: any): boolean => {
    return value && typeof value === 'string' && isoDateFormat.test(value);
};

export const handleDates = (body: any) => {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
            body[key] = new Date(value);
        } else if (typeof value === 'object') {
            handleDates(value);
        }
    }

    return body;
};
