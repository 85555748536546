import React, { useContext, useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import { useSelector } from 'react-redux';

import { IApiError } from '../../models/AppModels';
import { getSwagger } from '../../services/api/SwaggerApi';
import { isTokenExpired } from '../../utils/ApplicationUtils';
import * as Utils from '../../utils/ErrorUtils';
import log from '../../logger';

import { selectToken, selectTokenExpiryDate } from '../../redux/app/features/credentialsSlice';
import { AppContext } from '../../AppContext';

import 'swagger-ui-react/swagger-ui.css';

interface IApiResponse {
    data: any;
}

const Swagger = () => {
    const [swaggerJson, setSwaggerJson] = useState<Record<string, unknown>>({});

    const { setMessageBanner } = useContext(AppContext);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    useEffect(() => {
        !isTokenExpired(tokenExpiryDate ?? 0) &&
            getSwagger(token)
                .then((response: IApiResponse) => {
                    if (response.data.error) {
                        throw new Error(response.data.error.message);
                    }
                    log.debug('[SWAGGER-API] - Success');
                    setSwaggerJson(response.data);
                })
                .catch((error: IApiError) => {
                    setMessageBanner({
                        isBanner: true,
                        message: Utils.displayErrorMessage(error),
                        type: 'error'
                    });
                });
    }, []);

    return (
        <div className="swagger-container">
            <div className="swagger">
                <SwaggerUI spec={swaggerJson} />
            </div>
        </div>
    );
};
export default Swagger;
