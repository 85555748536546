import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableSortLabel } from '@airbus/components-react';
import { visuallyHidden } from '@mui/utils';

import { Application, IAppsVersionsData } from '../../models/AppModels';
import DataRow from './DataRow';

import { HEAD_CELLS } from '../../constants/MyApps';

import './MyApps.css';

type CustomSortDirection = 'asc' | 'desc';

export type RowDisplayData = {
    id: number;
    name: string;
    version?: string;
    state?: string;
    createdAt?: Date;
    signature?: string;
};

export type IHeadCell = {
    id: keyof RowDisplayData;
    label: string;
};

export type AppDisplayData = {
    mainApp: RowDisplayData;
    appVersions: RowDisplayData[];
};

type Props = {
    refreshAppList: () => void;
    apps: Application[];
    token: string | undefined;
    tokenExpiryDate: number | undefined;
};

const DataTable = ({ apps }: Props) => {
    const [order, setOrder] = useState<CustomSortDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof RowDisplayData>('name');

    const descendingComparator = (a: AppDisplayData, b: AppDisplayData, orderBy: keyof RowDisplayData) => {
        const paramA = a.mainApp[orderBy];
        const paramB = b.mainApp[orderBy];

        if (!paramA) {
            return 1;
        }
        if (!paramB) {
            return -1;
        }

        if (paramB < paramA) {
            return -1;
        }
        if (paramB > paramA) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order: CustomSortDirection, orderBy: keyof RowDisplayData) => {
        return order === 'desc'
            ? (a: AppDisplayData, b: AppDisplayData) => descendingComparator(a, b, orderBy)
            : (a: AppDisplayData, b: AppDisplayData) => -descendingComparator(a, b, orderBy);
    };

    const appDisplayInfos = apps.map((app: Application): AppDisplayData => {
        if (app.ife_app_versions.length) {
            const appLastVersion = app.ife_app_versions.filter((appVersion: IAppsVersionsData) => appVersion).reverse()[0];

            return {
                mainApp: {
                    id: app.id,
                    name: app.name,
                    version: appLastVersion.version,
                    state: appLastVersion.state,
                    createdAt: appLastVersion.createdAt,
                    signature: appLastVersion.signature
                },
                appVersions: app.ife_app_versions.map((appVersion) => ({
                    id: appVersion.id,
                    name: app.name,
                    version: appVersion.version,
                    state: appVersion.state,
                    createdAt: appVersion.createdAt,
                    signature: appVersion.signature
                }))
            };
        } else {
            return {
                mainApp: {
                    id: app.id,
                    name: app.name
                },
                appVersions: []
            };
        }
    });

    const handleRequestSort = (property: keyof RowDisplayData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div>
            <TableContainer className="table-container" component={Paper}>
                <Box sx={{ overflow: 'auto' }}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow className="table-header" sx={{ '& > *': { borderBottom: 'unset' } }}>
                                {HEAD_CELLS.map((headCell) => (
                                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => {
                                                handleRequestSort(headCell.id);
                                            }}>
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appDisplayInfos.length &&
                                appDisplayInfos
                                    .slice()
                                    .sort(getComparator(order, orderBy))
                                    .map((app: AppDisplayData) => {
                                        return <DataRow key={app.mainApp.id} row={app} />;
                                    })}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        </div>
    );
};

export default DataTable;
