import { Checkbox, CheckboxGroup } from '@airbus/components-react';
import React from 'react';
import { AircraftModel } from '../../../../../models/Aircraft';

type Props = {
    options: AircraftModel[];
    currentChosenOptions: AircraftModel[];
    onFilterChange: (selectedModel: AircraftModel[]) => void;
};

const ModelFilter = ({ options, currentChosenOptions, onFilterChange }: Props) => {
    return (
        <>
            <CheckboxGroup name="aircrafts-filter-by-aircrafts" value={currentChosenOptions} onChange={(e, value) => onFilterChange(value)}>
                {[...options].sort().map((option, index) => (
                    <Checkbox key={index} value={option} label={option} />
                ))}
            </CheckboxGroup>
        </>
    );
};

export default ModelFilter;
