import { IApiError } from '../models/AppModels';

export function displayErrorMessage(error: IApiError): string {
    let message = '';
    if (error.response) {
        message = error.response.data.errors.length ? error.response.data.errors[0].message : error.response.data.message;
    } else {
        message = error.message;
    }
    return message;
}

export type Error200 = {
    error: {
        className: string;
        code: number;
        error: any;
        message: string;
        name: string;
    };
};
