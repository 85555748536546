import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { selectToken, selectTokenExpiryDate } from '../../../redux/app/features/credentialsSlice';

import classes from './Schedule.module.css';
import CardApplications, { ChosenApplication as ChosenApplicationAppCard } from './cardApplications/CardApplications';
import CardAircrafts, { AircraftFilters } from './cardAircrafts/CardAircrafts';
import CardOverview, { ChosenApplication as ChosenApplicationOverviewCard } from './cardOverview/CardOverview';
import LoadingSpinner from '../../loaders/LoginSpinner';
import axios, { AxiosRequestConfig } from 'axios';
import { Application, IApiError } from '../../../models/AppModels';
import { handleDates } from '../../../utils/DateUtils';
import { apiUrl, isTokenExpired } from '../../../utils/ApplicationUtils';
import { useQuery } from 'react-query';
import { Aircraft } from '../../../models/Aircraft';
import { snakeCaseToCamelCase } from '../../../utils/TypeUtils';
import { displayErrorMessage } from '../../../utils/ErrorUtils';
import { equal, onlyUnique } from '../../../utils/ArrayUtils';
import { RemoteDeliveryBundle } from '../../../models/DeliveryBundle';
import { getDeliveryBundles } from '../../../services/api/Deployment';
import { DataTableQuery } from '../../admin/GenericTable';
import { defaultPageSize } from '../../admin/utils';
import { CancellationModalType } from '../CancellationModal';

const filterIsEmpty = (filter: AircraftFilters) => {
    return filter.name === '' && !filter.airlines.length && !filter.models.length;
};

const filterIsTheSame = (a: AircraftFilters, b: AircraftFilters) => {
    return a.name === b.name && equal(a.airlines, b.airlines) && equal(a.models, b.models);
};

const toOverviewChosenApp = (
    copy: Record<string, ChosenApplicationAppCard>,
    applications: Application[]
): ChosenApplicationOverviewCard[] => {
    return Object.entries(copy)
        .filter(([, chosenApp]) => chosenApp.appId !== undefined && chosenApp.version !== undefined && chosenApp.appVersionId !== undefined)
        .map(([, chosenApp]) => {
            const app = applications.find((app) => app.id === chosenApp.appId);
            if (!app) {
                return undefined;
            }

            const version = app.ife_app_versions.find((version) => version.version === chosenApp.version);
            if (!version) {
                return undefined;
            }

            return {
                id: app.id,
                name: app.name,
                state: app.state,
                chosenAppVersion: version,
                createdAt: app.createdAt,
                signature: app.signature
            };
        })
        .filter((app) => app) as ChosenApplicationOverviewCard[];
};

const Schedule = () => {
    const { setMessageBanner } = useContext(AppContext);
    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);
    const [filteredAircrafts, setFilteredAircrafts] = useState<Aircraft[]>([]);
    const [chosenApps, setChosenApps] = useState<Record<string, ChosenApplicationAppCard>>({});
    const [chosenAircrafts, setChosenAircrafts] = useState<Aircraft[]>([]);
    const [aircraftFilters, setAircraftFilters] = useState<AircraftFilters>({
        name: '',
        airlines: [],
        models: []
    });
    const [query] = useState<DataTableQuery>({
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy: []
    });
    const [syncTitle, setSyncTitle] = useState<string>('');
    const [isPartial, setIsPartial] = useState<boolean>(false);
    const [mustDeploy, setMustDeploy] = useState<boolean>(false);
    const [scheduledDeploymentsList, setScheduledDeploymentsList] = useState<RemoteDeliveryBundle[]>([]);

    const handleClose = () => {
        setModalProps({ ...modalProps, openModal: false });
    };
    const [modalProps, setModalProps] = useState<CancellationModalType>({
        openModal: false,
        popUpDeploymentBlockingType: '',
        popUpMessage: '',
        handleClose: handleClose
    });

    useEffect(() => {
        if (mustDeploy) {
            if (!navigator.onLine) {
                setMessageBanner({
                    isBanner: true,
                    message: `${i18n.t('shared.noNetwork')}`,
                    type: 'error'
                });
                return;
            }

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: 'Bearer ' + (token ?? ''),
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };

            axios
                .get(`${apiUrl}/ping`, options)
                .then(({ data }) => {
                    if (data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${data.error.message}`,
                            type: 'error'
                        });
                        setMustDeploy(false);
                        return;
                    }

                    const params = {
                        aircraftIds: chosenAircrafts.map((ac) => ac.id),
                        appVersionIds: Object.values(chosenApps).map((app) => app.appVersionId),
                        syncName: syncTitle,
                        isPartial: isPartial
                    };
                    axios
                        .post(`${apiUrl}/deploy-apps`, params, options)
                        .then(({ data }) => {
                            if (data.error) {
                                setMessageBanner({
                                    isBanner: true,
                                    message: `${data.error.message}`,
                                    type: 'error'
                                });
                                setMustDeploy(false);
                            } else {
                                setMessageBanner({
                                    isBanner: true,
                                    message: i18n.t('deployment.schedule.deploy_sync_started').replace('###&&&', syncTitle),
                                    type: 'success'
                                });
                                setAircraftFilters({
                                    name: '',
                                    airlines: [],
                                    models: []
                                });
                                setChosenAircrafts([]);
                                setChosenApps({});
                                setSyncTitle('');
                            }
                            setMustDeploy(false);
                        })
                        .catch((err) => {
                            setMessageBanner({
                                isBanner: true,
                                message: `${err.message}`,
                                type: 'error'
                            });
                            setMustDeploy(false);
                        });
                })
                .catch(() => {
                    setMessageBanner({
                        isBanner: true,
                        message: `${i18n.t('shared.noNetwork')}`,
                        type: 'error'
                    });
                    setMustDeploy(false);
                });
        }
    }, [mustDeploy]);

    const getApps = () => {
        const options = {
            headers: {
                Authorization: 'Bearer ' + (token ?? ''),
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                validationState: 'validated',
                filterByCompany: true
            }
        };

        return useQuery<Application[]>(
            ['get', 'app_enhanced'],
            () =>
                axios.get(`${apiUrl}/app_enhanced`, options).then(({ data }) => {
                    if (data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${data.error.message}`,
                            type: 'error'
                        });
                    } else {
                        const withDate = data.data.map(handleDates);
                        return withDate;
                    }
                }),
            {
                enabled: !isTokenExpired(tokenExpiryDate ?? 0),
                refetchOnWindowFocus: false,
                retry: false,
                onError: (err) => {
                    setMessageBanner({
                        isBanner: true,
                        message: displayErrorMessage(err as IApiError),
                        type: 'error'
                    });
                }
            }
        );
    };

    const getAircrafts = () => {
        const options: AxiosRequestConfig = {
            headers: {
                Authorization: 'Bearer ' + (token ?? ''),
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                addResponsibleAc: true,
                filterByCompany: true,
                type: 'AIRLINE',
                addDemoAircrafts: true
            }
        };

        return useQuery<Aircraft[]>(
            ['aircraft', 'get'],
            () =>
                axios.get(`${apiUrl}/aircraft`, options).then(({ data }) => {
                    if (data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${data.error.message}`,
                            type: 'error'
                        });
                    } else {
                        if (data.data) {
                            const aircrafts = data.data.map(handleDates).map((ac: any) => snakeCaseToCamelCase<Aircraft>(ac));
                            return aircrafts;
                        }
                    }
                }),
            {
                enabled: !isTokenExpired(tokenExpiryDate ?? 0),
                refetchOnWindowFocus: false,
                retry: false,
                onError: (err) => {
                    setMessageBanner({
                        isBanner: true,
                        message: displayErrorMessage(err as IApiError),
                        type: 'error'
                    });
                }
            }
        );
    };

    const { data: bundleData } = getDeliveryBundles(query, token, tokenExpiryDate);

    const cancelPlannedDeliveryBundles = () => {
        const deliveryTargetIds = scheduledDeploymentsList.map((dep) => dep.id);

        const params = {
            cancelEvent: true,
            deliveryTargetIds
        };

        const options: AxiosRequestConfig = {
            headers: {
                Authorization: 'Bearer ' + (token ?? ''),
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        axios.post(`${apiUrl}/delivery-event`, params, options).then(({ data }) => {
            console.log('data', data);
            if (data.error) {
                setMessageBanner({
                    isBanner: true,
                    message: `${data.error.message}`,
                    type: 'error'
                });
            } else if (data.status === 200 && data.message === 'ok') {
                setMustDeploy(true);
            } else {
                setMessageBanner({
                    isBanner: true,
                    message: i18n.t('deployment.schedule.cancel_planned_syncro_error'),
                    type: 'error'
                });
            }
        });
        handleClose();
    };

    const onDeploy = () => {
        const aircraftTailIds = chosenAircrafts.map((ac) => ac.tailId);
        const sheduledList =
            bundleData?.filter(
                (bundle) => (bundle.status === 'REQUESTED' || bundle.status === 'SQS_PUSHED') && aircraftTailIds.includes(bundle.tailId)
            ) || [];
        setScheduledDeploymentsList(sheduledList);

        const ongoingList =
            bundleData?.filter((bundle) => bundle.status === 'SYNC_STARTED' && aircraftTailIds.includes(bundle.tailId)) || [];

        if (ongoingList.length > 0) {
            setModalProps({
                ...modalProps,
                popUpMessage: i18n
                    .t('deployment.popUp.ongoing_deployment')
                    .replace('###&&&', `${ongoingList.map((dep) => dep.tailId).filter(onlyUnique)}`),
                popUpDeploymentBlockingType: 'ongoing',
                openModal: true
            });
        } else if (sheduledList.length > 0) {
            setModalProps({
                ...modalProps,
                popUpDeploymentBlockingType: 'planned',
                popUpMessage: i18n
                    .t('deployment.popUp.scheduled_deployment')
                    .replace('###&&&', `${sheduledList.map((dep) => dep.tailId).filter(onlyUnique)}`),
                openModal: true
            });
        } else {
            setMustDeploy(true);
        }
    };

    const { data: appsData, isLoading: appIsLoading } = getApps();
    const { data: aircraftsData, isLoading: acIsLoading } = getAircrafts();

    const applications: Application[] = Array.isArray(appsData) ? appsData : [];

    const aircrafts: Aircraft[] = Array.isArray(aircraftsData) ? aircraftsData : [];

    const filtered = aircrafts.filter(
        (a) =>
            (aircraftFilters.models.length === 0 || aircraftFilters.models.includes(a.model)) &&
            (aircraftFilters.airlines.length === 0 || aircraftFilters.airlines.includes(a.airlineCompanyId)) &&
            (aircraftFilters.name === '' || a.tailId.startsWith(aircraftFilters.name))
    );
    if (
        !equal(
            filteredAircrafts.map((a) => a.id),
            filtered.map((a) => a.id)
        )
    ) {
        setFilteredAircrafts(filtered);
    }

    const isLoading = appIsLoading || (acIsLoading && filterIsEmpty(aircraftFilters));
    if (isLoading) {
        return <LoadingSpinner putLoginLabel={false} fullPage={false} />;
    }

    return (
        <div className={classes.page}>
            <div className={classes.page_container}>
                <CardApplications
                    applications={applications}
                    commonClasses={classes}
                    onChosenAppChange={(apps: Record<string, ChosenApplicationAppCard>) => {
                        setChosenApps(apps);
                    }}
                    chosenApps={chosenApps}
                />
                <CardAircrafts
                    aircrafts={aircrafts}
                    filteredAircrafts={filteredAircrafts}
                    chosenAircrafts={chosenAircrafts}
                    commonClasses={classes}
                    onChosenAircraftsChange={(acs: Aircraft[]) => {
                        setChosenAircrafts(acs);
                    }}
                    showLoading={acIsLoading && !filterIsEmpty(aircraftFilters)}
                    onNewFilters={(newFilters: Partial<AircraftFilters>) => {
                        const joinFilters: AircraftFilters = {
                            name: newFilters.name ?? aircraftFilters.name,
                            airlines: newFilters.airlines ?? aircraftFilters.airlines,
                            models: newFilters.models ?? aircraftFilters.models
                        };
                        if (!filterIsTheSame(aircraftFilters, joinFilters)) {
                            setAircraftFilters(joinFilters);
                        }
                    }}
                />

                <CardOverview
                    commonClasses={classes}
                    syncTitle={syncTitle}
                    onSyncTitleChange={(syncTitle: string) => {
                        setSyncTitle(syncTitle);
                    }}
                    isPartial={isPartial}
                    onIsPartialChange={(_isPartial: boolean) => {
                        setIsPartial(_isPartial);
                    }}
                    chosenApplications={toOverviewChosenApp(chosenApps, applications)}
                    canDeploy={
                        Object.values(chosenApps).filter((app) => app.appId && app.appVersionId && app.version).length > 0 &&
                        chosenAircrafts.length > 0
                    }
                    onDeploy={() => {
                        onDeploy();
                    }}
                    modalProps={modalProps}
                    cancelPlannedDeliveryBundles={cancelPlannedDeliveryBundles}
                />
            </div>
        </div>
    );
};

export default Schedule;
