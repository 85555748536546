import React from 'react';
import i18n from 'i18next';
import { PostAdd } from '@airbus/icons/react';
import { Typography } from '@airbus/components-react';

import DataTable from './DataTable';
import { Application } from '../../models/AppModels';
import { useSelector } from 'react-redux';
import { selectToken, selectTokenExpiryDate } from '../../redux/app/features/credentialsSlice';
import LoginSpinner from '../loaders/LoginSpinner';

type Props = {
    handleRefreshAppList: () => void;
    apps: Application[] | undefined;
};

const AppsTable = (props: Props) => {
    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    if (props.apps === undefined) {
        return (
            <div className="apps-table">
                <LoginSpinner fullPage={false} putLoginLabel={false} />
            </div>
        );
    } else if (props.apps.length > 0) {
        return (
            <div className="apps-table">
                <DataTable
                    apps={props.apps}
                    refreshAppList={() => {
                        props.handleRefreshAppList();
                    }}
                    token={token}
                    tokenExpiryDate={tokenExpiryDate}
                />
            </div>
        );
    } else {
        return (
            <div className="content-applications">
                <PostAdd />
                <Typography variant="large" id="title">
                    {i18n.t('applications.noApps.title')}
                </Typography>
                <Typography variant="small" id="content">
                    {i18n.t('applications.noApps.content')}
                </Typography>
            </div>
        );
    }
};

export default AppsTable;
