import React from 'react';
import { Modal, Inline, Button } from '@airbus/components-react';
import i18n from 'i18next';

import classes from './CancelRemoteLoadingModal.module.css';

type Props = {
    isOpen: boolean;
    deploymentName: string;
    onClose: () => void;
    onProceed: () => void;
};

const CancelRemoteLoadingModal = ({ isOpen, deploymentName, onClose, onProceed }: Props) => {
    return (
        <Modal
            aria-labelledby="Cancel remote loading"
            aria-describedby="Modal to check if the user is sure he wants to cancel the remote loading"
            open={isOpen}
            onClose={onClose}>
            <div tabIndex={-1} className={classes.container}>
                <p>{i18n.t('deployment.history.table.cancelDeploymentModal.message').replace('###$$$', deploymentName)}</p>
                <Inline align="end" spacing="2-x">
                    <Button variant="primary" onClick={onProceed}>
                        &nbsp;&nbsp;&nbsp; {i18n.t('deployment.history.table.cancelDeploymentModal.proceed')} &nbsp;&nbsp;&nbsp;
                    </Button>

                    <Button onClick={() => onClose()}>{i18n.t('deployment.history.table.cancelDeploymentModal.cancel')}</Button>
                </Inline>
            </div>
        </Modal>
    );
};

export default CancelRemoteLoadingModal;
