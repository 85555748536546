import { snakeCaseToCamelCase as strSnakeCaseToCamelCase } from './StringUtils';

/**
 * Warning does not work for nested object for the moment
 * @param snake
 * @returns
 */
export const snakeCaseToCamelCase = <C>(snake: any): C => {
    const initiator: any = {};
    return Object.entries(snake).reduce((accumulator, [snakeKey, value]) => {
        const camelCaseKey = strSnakeCaseToCamelCase(snakeKey);
        accumulator[camelCaseKey] = value;
        return accumulator;
    }, initiator);
};
