import React from 'react';
import i18n from 'i18next';
import { Box, Tabs, Tab } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import { ROLE, USER, COMPANY, AIRCRAFT } from './utils';
import { Permissions } from '../../utils/permission';

type Props = {
    selectedTab: string;
    onTabChange: (tab: string) => void;
    adminpermission: Permissions;
};

const AdminTabs = ({ selectedTab, onTabChange, adminpermission }: Props) => {
    const history = useHistory();

    return (
        <Box sx={{ flexGrow: 1, flex: '0 0' }}>
            <Tabs
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    textAlign: 'left',
                    width: '200px'
                }}
                orientation="vertical"
                value={selectedTab}
                onChange={(event, value) => {
                    value === '/admin/user' &&
                        history.push({
                            pathname: '/admin/user',
                            state: { roleId: null, roleName: null }
                        });
                    onTabChange(value);
                }}>
                {adminpermission.adminRole && (
                    <Tab label={i18n.t('admin.tab1')} component={Link} to={`/admin/${ROLE}`} value={`/admin/${ROLE}`} />
                )}
                {adminpermission.adminCompany && (
                    <Tab label={i18n.t('admin.tab2')} component={Link} to={`/admin/${COMPANY}`} value={`/admin/${COMPANY}`} />
                )}
                {adminpermission.user && (
                    <Tab label={i18n.t('admin.tab3')} component={Link} to={`/admin/${USER}`} value={`/admin/${USER}`} />
                )}
                {adminpermission.adminAircraft && (
                    <Tab label={i18n.t('admin.tab4')} component={Link} to={`/admin/${AIRCRAFT}`} value={`/admin/${AIRCRAFT}`} />
                )}
            </Tabs>
        </Box>
    );
};

export default AdminTabs;
