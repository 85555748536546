import axios, { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../../AppContext';
import { DataTableQuery } from '../../components/admin/GenericTable';
import { IApiError } from '../../models/AppModels';
import { RemoteDeliveryBundle } from '../../models/DeliveryBundle';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';
import { getSortForDataTable } from '../../utils/DataTableUtils';
import { handleDates } from '../../utils/DateUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';

export const getDeliveryBundles = (
    query: DataTableQuery,
    token: any,
    tokenExpiryDate: number | undefined,
    pageCount?: number | undefined,
    setPageCount?: (pc: number) => void | undefined,
    rowCount?: number | undefined,
    setRowCount?: (rc: number) => void | undefined
) => {
    const { setMessageBanner } = useContext(AppContext);
    const params = getSortForDataTable(query, 'created_at');

    const options: AxiosRequestConfig = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8'
        },
        params
    };

    return useQuery<RemoteDeliveryBundle[]>(
        ['get', 'bundle_enhanced', query],
        () =>
            axios.get(`${apiUrl}/bundle-enhanced`, options).then(({ data }) => {
                if (data.error) {
                    setMessageBanner({
                        isBanner: true,
                        message: `${data.error.message}`,
                        type: 'error'
                    });
                } else {
                    if (data.data) {
                        const bundles = data.data.map(handleDates);
                        if (data.total !== rowCount) {
                            setRowCount && setRowCount(data.total);
                        }
                        const newPageCount = Math.ceil(data.total / query.pageSize);
                        if (newPageCount !== pageCount) {
                            setPageCount && setPageCount(newPageCount);
                        }
                        return bundles;
                    }
                }
            }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0),
            refetchOnWindowFocus: false,
            retry: false,
            onError: (err) => {
                setMessageBanner({
                    isBanner: true,
                    message: displayErrorMessage(err as IApiError),
                    type: 'error'
                });
            }
        }
    );
};
