import * as React from 'react';

function PlusIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M13.6666 7.01184C13.6666 7.37331 13.3736 7.66634 13.0121 7.66634H7.66659V13.0088C7.66659 13.3719 7.37218 13.6663 7.00902 13.6663C6.6358 13.6663 6.33325 13.3638 6.33325 12.9906V7.66634H0.983442C0.624352 7.66634 0.333252 7.37524 0.333252 7.01615C0.333252 6.63886 0.639106 6.33301 1.0164 6.33301H6.33325V1.01361C6.33325 0.637725 6.63797 0.333008 7.01386 0.333008C7.37435 0.333008 7.66659 0.625245 7.66659 0.985737V6.33301H12.9878C13.3627 6.33301 13.6666 6.63693 13.6666 7.01184Z"
                fill="#063B9E"
            />
        </svg>
    );
}

export default PlusIcon;
