import React, { useState, useRef } from 'react';
import i18n from 'i18next';
import { Checkbox, CheckboxGroup, IconButton, Popover, SearchInput } from '@airbus/components-react';

import SettingIcon from '../../../icons/SettingIcon';
import { Aircraft, AircraftModel } from '../../../../models/Aircraft';

import './CardAircrafts.css';
import classes from './CardAircrafts.module.css';
import LoadingSpinner from '../../../loaders/LoginSpinner';
import AircraftFilter from './AircraftFilter';

type Airline = {
    id: number;
    name: string;
};

export type AircraftFilters = {
    name: string;
    airlines: number[];
    models: AircraftModel[];
};

type Props = {
    aircrafts: Aircraft[];
    filteredAircrafts: Aircraft[];
    commonClasses: {
        readonly [key: string]: string;
    };
    chosenAircrafts: Aircraft[];
    onChosenAircraftsChange: (acs: Aircraft[]) => void;
    showLoading: boolean;
    onNewFilters: (filter: Partial<AircraftFilters>) => void;
};

const CardAircrafts = ({
    aircrafts,
    filteredAircrafts,
    commonClasses,
    chosenAircrafts,
    onChosenAircraftsChange,
    showLoading,
    onNewFilters
}: Props) => {
    const buttonFilterRef = useRef(null);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [airlineFilters, setAirlineFilters] = useState<number[]>([]);
    const [modelFilters, setModelFilters] = useState<AircraftModel[]>([]);

    const copyChosenAircrafts = (): Aircraft[] => {
        return JSON.parse(JSON.stringify(chosenAircrafts));
    };

    const onSelectAc = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
        const copy = copyChosenAircrafts();
        const foundInChosen = copy.find((ac) => ac.id.toString() === value[0]);
        if (foundInChosen === undefined) {
            const foundInFilter = filteredAircrafts.find((ac) => ac.id.toString() === value[0]);
            if (foundInFilter) {
                copy.push(foundInFilter);
                onChosenAircraftsChange(copy);
            }
        }
    };

    return (
        <div className={commonClasses.container_card}>
            <div className={commonClasses.container_header}>
                <p className={commonClasses.container_title}>{i18n.t('deployment.schedule.aircrafts_to_deploy_title')}</p>
                <p className={commonClasses.container_subtitle}>
                    {filteredAircrafts.length
                        ? `${filteredAircrafts.length} ${i18n.t('deployment.schedule.aircrafts_to_deploy_subtitle')}`
                        : i18n.t('deployment.schedule.aircraft_to_deploy_subtitle')}
                </p>
                <div className={commonClasses.line} />
                <div className={classes.search}>
                    <div className={classes.search_input}>
                        <SearchInput
                            // disabled={showLoading}
                            placeholder={i18n.t('deployment.schedule.aircrafts_to_deploy_search')}
                            onChange={(evt, newValue) => {
                                onNewFilters({
                                    name: newValue
                                });
                            }}
                        />
                    </div>
                    <div className={classes.filter_button_container}>
                        <IconButton
                            ref={buttonFilterRef}
                            className={classes.filter_button}
                            variant="primary"
                            aria-label="Parameters"
                            onClick={() => setOpenFilter(true)}
                            aria-haspopup="dialog">
                            <SettingIcon />
                        </IconButton>
                        <Popover
                            open={openFilter}
                            referenceNode={buttonFilterRef.current}
                            placement={'right'}
                            fallbackPlacements={['left']}>
                            <AircraftFilter
                                airlines={aircrafts.reduce((accumulator, ac) => {
                                    if (ac.airlineCompany && accumulator.findIndex((a) => a.id === ac.airlineCompany?.id) === -1) {
                                        accumulator.push({
                                            id: ac.airlineCompany.id,
                                            name: ac.airlineCompany.name
                                        });
                                    }
                                    return accumulator;
                                }, [] as Airline[])}
                                currentChosenAirlines={airlineFilters}
                                models={aircrafts.reduce((accumulator, ac) => {
                                    if (accumulator.findIndex((a) => a === ac.model) === -1) {
                                        accumulator.push(ac.model);
                                    }
                                    return accumulator;
                                }, [] as AircraftModel[])}
                                currentChosenModels={modelFilters}
                                close={() => setOpenFilter(false)}
                                apply={(chosenAirlines: number[], chosenModels: AircraftModel[]) => {
                                    setAirlineFilters(chosenAirlines);
                                    setModelFilters(chosenModels);
                                    setOpenFilter(false);
                                    onNewFilters({
                                        airlines: chosenAirlines,
                                        models: chosenModels
                                    });
                                }}
                            />
                        </Popover>
                    </div>
                </div>
            </div>
            {showLoading ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : filteredAircrafts.length ? (
                <div className={commonClasses.container_body}>
                    {chosenAircrafts.length > 0 && (
                        <>
                            <div className={classes.all_aircrafts_title_container}>
                                <p className={classes.all_aircrafts_title}>
                                    {i18n.t('deployment.schedule.aircrafts_to_deploy_selected_aircrafts')}
                                </p>
                            </div>
                            <CheckboxGroup
                                name="aircrafts-to-deploy"
                                value={chosenAircrafts.map((ac) => ac.id.toString())}
                                onChange={(e, v: string[]) => {
                                    onChosenAircraftsChange(
                                        v
                                            .map((chosenAc) => filteredAircrafts.find((ac) => ac.id === Number(chosenAc)))
                                            .filter((ac) => ac) as Aircraft[]
                                    );
                                }}>
                                {chosenAircrafts.map((ac) => {
                                    return (
                                        <Checkbox
                                            className="aircraft-card-checkbox"
                                            key={ac.id}
                                            value={ac.id.toString()}
                                            label={ac.tailId}
                                        />
                                    );
                                })}
                            </CheckboxGroup>
                        </>
                    )}

                    <div className={classes.all_aircrafts_title_container}>
                        <p className={classes.all_aircrafts_title}>
                            {i18n.t('deployment.schedule.aircrafts_to_deploy_unselected_aircrafts')}
                        </p>
                    </div>
                    <CheckboxGroup name="aircrafts-to-not-deploy" value={[]} onChange={onSelectAc}>
                        {filteredAircrafts
                            .filter((ac1) => chosenAircrafts.find((ac2) => ac1.id === ac2.id) === undefined)
                            .map((ac) => (
                                <Checkbox className="aircraft-card-checkbox" key={ac.id} value={ac.id.toString()} label={ac.tailId} />
                            ))}
                    </CheckboxGroup>
                </div>
            ) : (
                <p>{i18n.t('deployment.schedule.aircrafts_to_deploy_no_aircrafts')}</p>
            )}
        </div>
    );
};

export default CardAircrafts;
