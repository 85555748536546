import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import * as DateUtils from '../../utils/DateUtils';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Chip } from '@airbus/components-react';
import { Chevrondown, Chevronup, SubdirectoryArrowRight } from '@airbus/icons/react';

import { AppDisplayData, RowDisplayData } from './DataTable';
import CopyText from './CopyText';
import { APP_STATUS } from '../../constants/MyApps';

import '../../i18n/config';

type AppVersionDisplayContent = {
    id: number;
    name: string;
    version: string;
    stateStatus: React.ReactElement | string;
    createdAt: string;
    signature?: string;
};

type AppRowContent = {
    mainRow: AppVersionDisplayContent;
    subRows: AppVersionDisplayContent[];
};

type Props = {
    row: AppDisplayData;
};

function displayStatus(state: string | undefined): React.ReactElement | string {
    if (state !== undefined) {
        return (
            <Chip
                style={{
                    color: 'white',
                    backgroundColor: APP_STATUS(state).COLOR
                }}
                label={APP_STATUS(state).LABEL}
            />
        );
    } else {
        return '-';
    }
}

const toContent = (app: RowDisplayData): AppVersionDisplayContent => ({
    id: app.id,
    name: app.name,
    version: app.version ?? '-',
    stateStatus: displayStatus(app.state),
    createdAt: app.createdAt ? DateUtils.prettyDate(app.createdAt) : '-',
    signature: app.signature
});

const appDataToRow = (app: AppDisplayData): AppRowContent => ({
    mainRow: toContent(app.mainApp),
    subRows: app.appVersions.map(toContent)
});

const DataRow = ({ row }: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    // Display appsVersions from the most recent date
    row.appVersions.sort((a: RowDisplayData, b: RowDisplayData) => {
        return (b.createdAt ? b.createdAt.getTime() : 0) - (a.createdAt ? a.createdAt.getTime() : 0);
    });

    const appContent: AppRowContent = appDataToRow(row);

    return (
        <>
            <tr style={open ? { height: '2em' } : { height: '0.5em' }} />
            <TableRow
                className="data-row"
                style={
                    open
                        ? {
                              marginBottom: '5%',
                              backgroundColor: '#00205b',
                              border: '3px solid #00205b'
                          }
                        : {}
                }>
                <TableCell style={open ? { color: 'white', fontWeight: 'bold' } : { fontWeight: 'bold' }}>
                    {appContent.mainRow.name}
                </TableCell>
                <TableCell style={open ? { color: 'white' } : {}}>{!open && appContent.mainRow.version}</TableCell>
                <TableCell style={open ? { color: 'white' } : {}}>{!open && appContent.mainRow.createdAt}</TableCell>
                <TableCell>{!open && appContent.mainRow.stateStatus}</TableCell>
                <TableCell>{!open && appContent.mainRow.signature && <CopyText text={appContent.mainRow.signature} />}</TableCell>
                <TableCell>
                    <IconButton
                        style={open ? { color: 'white' } : { color: '#063b9e' }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <Chevronup /> : <Chevrondown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow key={appContent.mainRow.name} style={open ? { border: '3px solid #00205b' } : {}}>
                <TableCell key={appContent.mainRow.name} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table>
                                <TableBody>
                                    {appContent.subRows.length > 0 &&
                                        appContent.subRows.map((appVersion: AppVersionDisplayContent, index: number) => (
                                            <TableRow
                                                key={index}
                                                style={{
                                                    borderBottom: '1px solid lightgrey'
                                                }}>
                                                <TableCell style={{ width: '28%' }}>
                                                    <SubdirectoryArrowRight
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: 'lightgrey',
                                                            width: '2em',
                                                            height: '2em',
                                                            margin: '0 0.5em -0.5em -0.5em'
                                                        }}
                                                    />
                                                    {appVersion.version || '-'}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        width: '18.5%'
                                                    }}>
                                                    {appVersion.createdAt}
                                                </TableCell>
                                                <TableCell>{appVersion.stateStatus}</TableCell>
                                                {appVersion.signature && (
                                                    <TableCell>
                                                        <CopyText text={appVersion.signature} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DataRow;
