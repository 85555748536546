import React, { useState } from 'react';
import i18n from 'i18next';

import { Button, Input } from '@airbus/components-react';
import { Application, IAppsVersionsData } from '../../../../models/AppModels';

import classes from './CardOverview.module.css';
import ArrowCircleIcon from '../../../icons/ArrowCircleIcon';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CancellationModal, { CancellationModalType } from '../../CancellationModal';

export type ChosenApplication = Omit<Application, 'ife_app_version'> & {
    chosenAppVersion: IAppsVersionsData;
};

type Props = {
    chosenApplications: ChosenApplication[];
    commonClasses: {
        readonly [key: string]: string;
    };
    canDeploy: boolean;
    onDeploy: () => void;
    syncTitle: string;
    onSyncTitleChange: (syncTitle: string) => void;
    isPartial: boolean;
    onIsPartialChange: (isPartial: boolean) => void;
    modalProps: CancellationModalType;
    cancelPlannedDeliveryBundles: () => void;
};

const CardOverview = ({
    // chosenApplications,
    commonClasses,
    canDeploy,
    onDeploy,
    syncTitle,
    onSyncTitleChange,
    isPartial,
    onIsPartialChange,
    modalProps,
    cancelPlannedDeliveryBundles
}: Props) => {
    const [optionType, setOptionType] = useState<'4G' | 'SatCom'>('4G');

    // const size = chosenApplications.reduce((accumulator, current) => {
    //     return accumulator + current.chosenAppVersion.size;
    // }, 0);

    // const sizeInMb = bitsToMb(size, 2);

    return (
        <div className={commonClasses.container_card}>
            <div className={commonClasses.container_header}>
                <p className={commonClasses.container_title}>{i18n.t('deployment.schedule.overview_deploy_title')}</p>
                <p className={commonClasses.container_subtitle} style={{ visibility: 'hidden' }}>
                    -
                </p>
                <div className={commonClasses.line} />
            </div>
            <div className={commonClasses.container_body}>
                <div className={classes.sync_title_container}>
                    <p className={classes.sync_title}>{i18n.t('deployment.schedule.overview_deploy_sync_title')}</p>
                    <div className={classes.sync_title_input_container}>
                        <Input
                            value={syncTitle}
                            className={classes.sync_title_input}
                            placeholder={i18n.t('deployment.schedule.overview_deploy_sync_placeholder_title')}
                            onChange={(event, value) => onSyncTitleChange(value)}
                        />
                    </div>
                </div>
                {/* <div className={classes.sync_size_container}>
                    <p className={classes.sync_data_title}>
                        {i18n.t(
                            'deployment.schedule.overview_deploy_sync_size_title'
                        )}
                    </p>
                    <p className={classes.sync_data_value}>
                        {`${sizeInMb === '0.00' ? '-' : sizeInMb} MB`}
                    </p>
                </div>
                <div className={classes.sync_time_container}>
                    <p className={classes.sync_data_title}>
                        {i18n.t(
                            'deployment.schedule.overview_deploy_sync_time_title'
                        )}
                    </p>
                    <p className={classes.sync_data_value}>-</p>
                </div> */}

                <div className={classes.option_container}>
                    <p className={classes.sync_data_title}>{i18n.t('deployment.schedule.overview_deploy_options_title')}</p>
                    <div className={classes.option_toggles_container}>
                        <ToggleButtonGroup
                            className={classes.option_toggle}
                            color="primary"
                            value={optionType}
                            exclusive
                            fullWidth
                            onChange={(e, v) => {
                                if (v !== null) {
                                    setOptionType(v);
                                }
                            }}
                            aria-label="Sync type">
                            <ToggleButton value="4G">4G</ToggleButton>
                            <ToggleButton value="SatCom">SatCom</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            className={classes.option_toggle}
                            color="primary"
                            value={isPartial ? 'partial' : 'full'}
                            exclusive
                            fullWidth
                            onChange={(e, v) => {
                                if (v !== null) {
                                    onIsPartialChange(v === 'partial');
                                }
                            }}
                            aria-label="Sync mode">
                            <ToggleButton value="full">Full</ToggleButton>
                            <ToggleButton value="partial">Partial</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                <div className={classes.deploy_button}>
                    <Button
                        disabled={!canDeploy || syncTitle === ''}
                        variant="primary"
                        icon={<ArrowCircleIcon />}
                        onClick={() => {
                            onDeploy();
                        }}>
                        Launch deployment
                    </Button>
                    <CancellationModal modalProps={modalProps} cancelPlannedDeliveryBundles={cancelPlannedDeliveryBundles} />
                </div>
            </div>
        </div>
    );
};

export default CardOverview;
