import i18n from 'i18next';
import '../i18n/config';

export const MESSAGES = {
    ERROR: {
        TYPE: 'error',
        TITLE: `${i18n.t('messageBanner.title.error')}`
    },
    INFO: {
        TYPE: 'information',
        TITLE: `${i18n.t('messageBanner.title.info')}`
    },
    SUCCESS: {
        TYPE: 'success',
        TITLE: `${i18n.t('messageBanner.title.success')}`
    }
};
