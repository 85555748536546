import React, { useState, useMemo, useContext } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { DataTable, Toggle } from '@airbus/components-react';

import { RoleContext } from './RoleContext';
import { Permission } from '../../../models/PermissionModel';
import { Column } from 'react-table';

export const PermissionTable = ({
    options,
    selectedPermissions,
    onChangePermissions
}: {
    options: Permission[];
    selectedPermissions: number[] | undefined;
    onChangePermissions: (permissionIds: number[]) => void;
}) => {
    const { editMode } = useContext(RoleContext);

    const permissionsIdsForGivenRole = selectedPermissions?.map((id: number) => id).filter((x: number) => !!x);

    const [selectionModel, setSelectionModel] = useState<number[] | undefined>(permissionsIdsForGivenRole);

    useDeepCompareEffectNoCheck(() => {
        if (options && permissionsIdsForGivenRole) {
            const selectionIds = options
                ?.filter(({ id }: Permission) => permissionsIdsForGivenRole.includes(id))
                .map((elt: Permission) => elt.id);

            setSelectionModel(selectionIds);
            onChangePermissions(selectionIds);
        } else {
            setSelectionModel([]);
            onChangePermissions([]);
        }
    }, [options, permissionsIdsForGivenRole, editMode]);

    const columns: Column<Permission>[] = useMemo(() => {
        return [
            {
                header: 'id',
                id: 'id',
                minWidth: 15,
                width: 15,
                accessor: (row: Permission) => row.id,
                Cell: ({ value }: { value: number }) => (
                    <Toggle checked={!!selectionModel?.includes(value)} disabled={!editMode} size="small" />
                )
            },
            {
                header: 'Permission',
                id: 'Permission',
                minWidth: 300,
                accessor: (row: Permission) => {
                    if (row.type === 'db' && row.permission_db) {
                        return `${row.permission_db.action} ${row.permission_db.subject} in database`;
                    } else if (row.type === 'page' && row.permission_page) {
                        return `${row.permission_page.action} ${row.permission_page.page} page`;
                    }
                    return 'NAME ERROR';
                },
                Cell: ({ value }: { value: string }) => <div className="header-cell">{value}</div>
            }
        ];
    }, [selectionModel?.length, editMode]);

    return (
        <DataTable
            columns={columns}
            data={options}
            defaultRowsPerPage={options.length}
            className={'permissionTable'}
            onRowClick={({ id }: Permission) => {
                if (editMode) {
                    let result: number[];
                    if (selectionModel && !selectionModel.includes(id)) result = [...selectionModel, id];
                    else result = [...(selectionModel ? selectionModel.filter((elt) => elt !== id) : [])];
                    setSelectionModel(result);
                    onChangePermissions(result);
                }
            }}></DataTable>
    );
};
