export enum EnvStage {
    Dev = 'dev',
    Int = 'int',
    Val = 'val',
    Prod = 'prod'
}

export const IdeLinks: Record<EnvStage, string> = {
    [EnvStage.Dev]: 'https://dev.ide.airspacelink.airbus.com/',
    [EnvStage.Int]: 'https://int.ide.airspacelink.airbus.com/',
    [EnvStage.Val]: 'https://val.ide.airspacelink.airbus.com/',
    [EnvStage.Prod]: 'https://ide.airspacelink.airbus.com/'
};

export const DataSimuLinks: Record<EnvStage, string> = {
    [EnvStage.Dev]: 'https://dev.data-simu.airspacelink.airbus.com/',
    [EnvStage.Int]: 'https://int.data-simu.airspacelink.airbus.com/',
    [EnvStage.Val]: 'https://val.data-simu.airspacelink.airbus.com/',
    [EnvStage.Prod]: 'https://data-simu.airspacelink.airbus.com/'
};

export const PaxSimuLinks: Record<EnvStage, string> = {
    [EnvStage.Dev]: 'https://dev.pax-simu.airspacelink.airbus.com/',
    [EnvStage.Int]: 'https://int.pax-simu.airspacelink.airbus.com/',
    [EnvStage.Val]: 'https://val.pax-simu.airspacelink.airbus.com/',
    [EnvStage.Prod]: 'https://pax-simu.airspacelink.airbus.com/'
};

export const CrewSimuLinks: Record<EnvStage, string> = {
    [EnvStage.Dev]: 'https://dev.crew-simu.airspacelink.airbus.com/',
    [EnvStage.Int]: 'https://int.crew-simu.airspacelink.airbus.com/',
    [EnvStage.Val]: 'https://val.crew-simu.airspacelink.airbus.com/',
    [EnvStage.Prod]: 'https://crew-simu.airspacelink.airbus.com/'
};

export const LoadingSimuLinks: Record<EnvStage, string> = {
    [EnvStage.Dev]: 'https://dev.loading-simu.airspacelink.airbus.com/',
    [EnvStage.Int]: 'https://int.loading-simu.airspacelink.airbus.com/',
    [EnvStage.Val]: 'https://val.loading-simu.airspacelink.airbus.com/',
    [EnvStage.Prod]: 'https://loading-simu.airspacelink.airbus.com/'
};
