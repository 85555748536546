import React, { useEffect, useState, useContext } from 'react';
import i18n from 'i18next';

import { RoleUserDetails } from './RoleUserDetails';
import { RolePermissionTable } from './RolePermissionTable';
import { useCustomMutation, useCustomQuery } from '../utils';

import { IApiError } from '../../../models/AppModels';
import { RoleContext } from './RoleContext';
import { AppContext } from '../../../AppContext';

import { displayErrorMessage } from '../../../utils/ErrorUtils';
import { useHistory } from 'react-router-dom';
import { EnhanceRole } from '../../../models/RoleModel';

type Props = {
    selectedRole: { value: string; label: string };
    token: string | undefined;
    tokenExpiryDate: number | undefined;
    setSelectedTab: (newTab: string) => void;
};

const RoleDetails = ({ selectedRole, token, tokenExpiryDate, setSelectedTab }: Props) => {
    const { value: id, label } = selectedRole || {
        value: undefined,
        label: undefined
    };
    const { setRole, refetch, setEditMode } = useContext(RoleContext);
    const { setMessageBanner } = useContext(AppContext);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [newLabel, setnewLabel] = useState<string>(label);
    const history = useHistory();

    useEffect(() => {
        if (label) setnewLabel(label);
    }, [label]);

    const { data: enhancedRole, refetch: refetchEnhanceRole } = useCustomQuery<EnhanceRole, any>(
        token,
        tokenExpiryDate,
        `enhance-role/${id}`,
        !!id,
        false,
        'id',
        {},
        () =>
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.roles.errors.get'),
                type: 'error'
            })
    );

    const { mutate: editRole } = useCustomMutation(
        token,
        tokenExpiryDate,
        `role/${id}`,
        {
            id,
            label: newLabel,
            permissions: selectedPermissions
        },
        'patch',
        async () => {
            await refetch();
            await refetchEnhanceRole();
            setEditMode(false);
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.roles.success.edit'),
                type: 'success'
            });
        },
        (err) =>
            setMessageBanner({
                isBanner: true,
                message: displayErrorMessage(err as IApiError),
                type: 'error'
            })
    );

    const { mutate: deleteRole } = useCustomMutation(
        token,
        tokenExpiryDate,
        `role/${id}`,
        {},
        'delete',
        async () => {
            await refetch();
            setRole(0);
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.roles.success.delete'),
                type: 'success'
            });
        },
        () =>
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.roles.errors.delete'),
                type: 'error'
            })
    );

    const onClickUsers = () => {
        setSelectedTab('/admin/user');
        history.push({
            pathname: '/admin/user',
            state: { roleId: id, roleName: newLabel }
        });
    };

    return (
        <>
            {selectedRole && (
                <div className="role-user-details-container">
                    <RoleUserDetails
                        selectedRole={selectedRole}
                        roleUsers={enhancedRole?.users?.map((user: { id: number }) => user.id)}
                        onClickSave={() => {
                            // TODO : If the fact that we can edit a page permission is normal,
                            // it's here that we need to refetch the current-user permission
                            // and then reinit them in casl
                            editRole();
                        }}
                        onClickDelete={() => {
                            deleteRole();
                        }}
                        onClickUsers={onClickUsers}
                        onChangeRoleLabel={(l: string) => setnewLabel(l)}
                        newLabel={newLabel}
                    />
                    <RolePermissionTable
                        token={token}
                        tokenExpiryDate={tokenExpiryDate}
                        rolePermissions={enhancedRole?.permissions?.map((user: { id: number }) => user.id)}
                        onChangePermissions={setSelectedPermissions}
                    />
                </div>
            )}
        </>
    );
};

export default RoleDetails;
