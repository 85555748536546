import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { ColorModeProvider, Typography } from '@airbus/components-react';

import log from '../../logger';
import { IApiError, IApiResponse, Application } from '../../models/AppModels';
import { getEnhancedApplications } from '../../services/api/MyAppsApi';
import { AppContext } from '../../AppContext';
import { isTokenExpired } from '../../utils/ApplicationUtils';
import * as Utils from '../../utils/ErrorUtils';
import { selectToken, selectTokenExpiryDate } from '../../redux/app/features/credentialsSlice';
import AppsTable from './AppsTable';
import { handleDates } from '../../utils/DateUtils';
import '../../i18n/config';

import './MyApps.css';

const MyApps = () => {
    const [applications, setApplications] = useState<Application[]>();
    const { setMessageBanner } = useContext(AppContext);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const getAppsVersionsCall = (): void => {
        log.debug('[MY_APPS_VERSIONS] - Starts call');

        !isTokenExpired(tokenExpiryDate ?? 0) &&
            getEnhancedApplications(token, true)
                .then((response: IApiResponse) => {
                    if (response.data.error) {
                        throw new Error(response.data.error.message);
                    }
                    log.debug('[MY_APPS_VERSIONS] - Success');
                    const withDate = response.data.data.map(handleDates);
                    setApplications(withDate);
                })
                .catch((error: IApiError) => {
                    const message = Utils.displayErrorMessage(error);
                    log.error('[MY_APPS_VERSIONS] - Error => ', message);
                    setMessageBanner({
                        isBanner: true,
                        message: message,
                        type: 'error'
                    });
                });
    };

    useEffect(() => {
        getAppsVersionsCall();
    }, []);

    return (
        <div className="center">
            <ColorModeProvider mode="dark">
                <div className="bg-primaryblue-90 main-page-title">
                    <div className="text-left">
                        <Typography variant="h2">{i18n.t('applications.title')}</Typography>
                    </div>
                </div>
            </ColorModeProvider>
            <AppsTable apps={applications} handleRefreshAppList={getAppsVersionsCall} />
        </div>
    );
};

export default MyApps;
