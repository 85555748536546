import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@airbus/components-react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import i18n from 'i18next';

import { RoleSelect } from './RoleSelect';
import RoleDetails from './RoleDetails';
import { RoleContext } from './RoleContext';
import { AppContext } from '../../../AppContext';

import { displayAdminTextTranslation, useCustomQuery } from '../utils';
import RoleCreation from './RoleCreation';
import { selectToken, selectTokenExpiryDate } from '../../../redux/app/features/credentialsSlice';
import { useSelector } from 'react-redux';
import Role from '../../../models/RoleModel';
import { displayErrorMessage } from '../../../utils/ErrorUtils';
import { IApiError } from '../../../models/AppModels';

type Props = {
    setSelectedTab: (newTab: string) => void;
};

const RolePage = ({ setSelectedTab }: Props) => {
    const [selectedRoleId, setSelectedRoleId] = useState<number | undefined>(0);
    const [editMode, setEditMode] = useState<boolean>(false);

    const { setMessageBanner } = useContext(AppContext);

    const [openSideNav, setOpenSideNav] = useState<boolean>(false);
    const [optionsData, setOptionsData] = useState<any>();

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const { pathname } = useLocation();

    const { data: roles, refetch } = useCustomQuery<Role[], any>(
        token,
        tokenExpiryDate,
        'role',
        true,
        false,
        'label',
        { isStatic: 'false' },
        (err) => {
            setMessageBanner({
                isBanner: true,
                message: displayErrorMessage(err as IApiError),
                type: 'error'
            });
        }
    );

    useDeepCompareEffectNoCheck(() => {
        if (roles?.length) {
            setOptionsData(
                roles.map((role: Role) => ({
                    value: role.id,
                    label: role.label
                }))
            );
        } else setOptionsData([]);
    }, [roles, selectedRoleId]);

    useDeepCompareEffectNoCheck(() => {
        if (selectedRoleId === 0 && optionsData?.length > 0) {
            setSelectedRoleId(optionsData[0].value);
        }
    }, [optionsData, selectedRoleId]);

    return (
        <RoleContext.Provider
            value={{
                selectedRoleId,
                setRole: (role: number | undefined) => setSelectedRoleId(role),
                editMode,
                setEditMode: (mode: boolean) => setEditMode(mode),
                refetch: () => refetch()
            }}>
            <div className="role-page">
                <div className="role-page-container">
                    <div className="role-page-sub-container">
                        <RoleSelect options={optionsData} />
                        <Button variant="primary" onClick={() => setOpenSideNav(true)}>
                            {displayAdminTextTranslation(pathname, 'button')}
                        </Button>
                    </div>

                    <div className="role-details-container">
                        {selectedRoleId ? (
                            <RoleDetails
                                selectedRole={optionsData?.find(({ value }: { value: number }) => {
                                    return value === Number(selectedRoleId);
                                })}
                                setSelectedTab={setSelectedTab}
                                token={token}
                                tokenExpiryDate={tokenExpiryDate}
                            />
                        ) : (
                            <div>
                                <span className="display-no-role">{i18n.t(`admin.roles.emptyList`)}</span>
                                <span className="display-no-role">{i18n.t(`admin.roles.noData`)}</span>
                            </div>
                        )}
                    </div>
                </div>
                {openSideNav && (
                    <div>
                        <RoleCreation
                            token={token}
                            tokenExpiryDate={tokenExpiryDate}
                            onClose={() => setOpenSideNav(false)}
                            onSuccess={() => {
                                setOpenSideNav(false);
                            }}
                        />
                    </div>
                )}
            </div>
        </RoleContext.Provider>
    );
};

export default RolePage;
