import config from '../config/config.json';
import { EnvStage } from '../constants/EnvStage';

export function isSwaggerVisible(stage: string): boolean {
    const visibleSwaggerStages = [EnvStage.Dev, EnvStage.Int];
    const swaggerVisible = Object.values(visibleSwaggerStages).find((visibleSwaggerStage) => visibleSwaggerStage === stage);
    return !!swaggerVisible;
}

export function isTokenExpired(expiryDate: number): boolean {
    return expiryDate <= Date.now();
}

export function displayUserName(userInfo: any): string {
    const firstName = userInfo.given_name;
    const familyName = userInfo.family_name;
    return `${firstName} ${familyName}`;
}

export const apiUrl = config?.apiUrl || process.env.REACT_APP_API_URL;
export const redirectUri = config?.redirectUri || process.env.REACT_APP_REDIRECT_URI;

export const envStage: EnvStage = (config?.stage as EnvStage) || EnvStage.Int;
