import { Ability, AbilityBuilder, AnyMongoAbility, defineAbility } from '@casl/ability';
import { PermissionPage } from '../models/PermissionModel';

const ability: AnyMongoAbility = defineAbility(() => {
    return;
});

const can = (action: string, subject: string) => {
    return ability.can(action, subject);
};

export const initUserPagePermissions = (userPermissions: PermissionPage[]) => {
    const { can, rules } = new AbilityBuilder(Ability);

    userPermissions.forEach((permission: PermissionPage) => {
        can(permission.action, permission.page);
    });

    ability.update(rules);
};

export type Permissions = {
    user: boolean;
    adminRole: boolean;
    adminCompany: boolean;
    adminAircraft: boolean;
    admin: boolean;
    validationCenter: boolean;
    deployment: boolean;
    aircraft: boolean;
};

export const computePermissions = (): Permissions => {
    const canReadAdminUser = can('read', 'admin user');
    const canReadAdminCompany = can('read', 'admin company');
    const canReadAdminRole = can('read', 'admin role');
    const canReadAdminAircraft = can('read', 'admin aircraft');
    const canReadValidationCenter = can('read', 'validation center');
    const canReadDeployment = can('read', 'deployment');
    const canReadAircraft = can('read', 'aircraft');

    return {
        user: canReadAdminUser,
        adminRole: canReadAdminRole,
        adminCompany: canReadAdminCompany,
        adminAircraft: canReadAdminAircraft,
        admin: canReadAdminCompany || canReadAdminRole || canReadAdminUser || canReadAdminAircraft,
        validationCenter: canReadValidationCenter,
        deployment: canReadDeployment,
        aircraft: canReadAircraft
    };
};

export default can;
