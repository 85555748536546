import '../i18n/config';
import { prettyDate } from './DateUtils';
import { ApplicationValidatorAPIMessage } from '../models/ApplicationValidation';
import { APPLICATION_DETAILS_VALIDATION } from '../constants/Validation';

interface IComment {
    by: string;
    comment: Text | string | undefined;
    at: string;
    state: string | null;
    isRevalidated?: boolean;
    roles?: string[];
}
export function parseComment(application: ApplicationValidatorAPIMessage | undefined): IComment[] {
    const commentsSet: Set<string> = new Set();
    const comments: IComment[] = [];
    const hasRejectionByUserRole = function (userId: number, roleCheck: string) {
        return (
            application &&
            application.validations.some(({ validation_state, user_id, role: { label: roleLabel } }) => {
                return validation_state === APPLICATION_DETAILS_VALIDATION.REJECTED && user_id === userId && roleLabel === roleCheck;
            })
        );
    };
    if (application) {
        application.validations.filter((v) => {
            if (!commentsSet.has(v.user_id + '-' + v.created_at + '-' + hasRejectionByUserRole(v.user_id, v.role.label))) {
                commentsSet.add(v.user_id + '-' + v.created_at + '-' + hasRejectionByUserRole(v.user_id, v.role.label));
                comments.push({
                    by: parseName(v.user.name),
                    comment: v.comment,
                    at: prettyDate(v.created_at),
                    state: v.validation_state,
                    isRevalidated:
                        v.validation_state === APPLICATION_DETAILS_VALIDATION.VALIDATED && hasRejectionByUserRole(v.user_id, v.role.label),
                    roles: [v.role.label]
                });
                return true;
            }
            // if the last comment is same as this comment,
            // add the role labels to last comment
            comments[comments.length - 1].roles?.push(v.role.label);
            return false;
        });
    }
    return comments;
}

export function parseValidationLabels(application: ApplicationValidatorAPIMessage | undefined) {
    if (!application) {
        return { validated: [], rejected: [] };
    }
    // first retreive validations
    const validatedByWithRole: string[] = [];
    const validations = application.validations;
    const validated = validations.filter(({ validation_state, user_id, role: { label: roleLabel } }) => {
        if (
            validation_state === APPLICATION_DETAILS_VALIDATION.VALIDATED &&
            !validatedByWithRole.includes(user_id.toString() + '-' + roleLabel)
        ) {
            validatedByWithRole.push(user_id.toString() + '-' + roleLabel);
            return true;
        }
    });
    // then only retreive those rejected which weren't revalidated later
    const rejected = validations.filter(({ validation_state, user_id, role: { label: roleLabel } }) => {
        if (
            validation_state === APPLICATION_DETAILS_VALIDATION.REJECTED &&
            !validatedByWithRole.includes(user_id.toString() + '-' + roleLabel)
        ) {
            return true;
        }
    });
    return { validated, rejected };
}
export function parseName(fullname: string) {
    return fullname[0].toLocaleUpperCase() + '. ' + fullname.split(' ')[1].toLocaleUpperCase();
}
