import axios, { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../../AppContext';
import { AircraftDeliveryBundle } from '../../models/AircraftDeliveryBundle';
import { IApiError } from '../../models/AppModels';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';
import { handleDates } from '../../utils/DateUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';

export const getDeliveryBundlesHistory = (aircraftId: number | undefined, token: any, tokenExpiryDate: number | undefined) => {
    const { setMessageBanner } = useContext(AppContext);

    const options: AxiosRequestConfig = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };

    return useQuery<AircraftDeliveryBundle[]>(
        ['get', 'bundle_enhanced'],
        () =>
            axios.get(`${apiUrl}/bundle-history-enhanced/${aircraftId}`, options).then(({ data }) => {
                if (data.error) {
                    setMessageBanner({
                        isBanner: true,
                        message: `${data.error.message}`,
                        type: 'error'
                    });
                } else {
                    if (data.data) {
                        const bundles = data.data.map(handleDates);

                        return bundles;
                    }
                }
            }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0),
            refetchOnWindowFocus: false,
            retry: false,
            onError: (err) => {
                setMessageBanner({
                    isBanner: true,
                    message: displayErrorMessage(err as IApiError),
                    type: 'error'
                });
            }
        }
    );
};
