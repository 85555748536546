import React from 'react';
import { Input } from '@airbus/components-react';

type Props = {
    onChangeLabel: (e: string) => void;
    label: string | undefined;
    displayLabel?: boolean;
    inSidePanel?: boolean;
};

const RoleForm = ({ onChangeLabel, label, displayLabel = true, inSidePanel = false }: Props) => {
    return (
        <div className="role-input" style={{ margin: inSidePanel ? '1.2rem' : 0 }}>
            <Input
                required
                autoFocus
                id="name"
                placeholder={displayLabel ? 'Name' : ''}
                onChange={(e: { target: { value: string } }) => {
                    onChangeLabel(e.target.value);
                }}
                value={label || ''}
            />
        </div>
    );
};

export default RoleForm;
