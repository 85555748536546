import { createContext } from 'react';

interface IRoleContext {
    selectedRoleId: number | undefined;
    setRole: (role: any) => void;
    editMode: boolean;
    setEditMode: (mode: boolean) => void;
    refetch: () => void;
}

const defaultState = {
    selectedRoleId: undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setRole: () => {
        return;
    },
    editMode: false,
    setEditMode: () => {
        return;
    },
    refetch: () => {
        return;
    }
};

export const RoleContext = createContext<IRoleContext>(defaultState);
