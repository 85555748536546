import React from 'react';
import i18n from 'i18next';
import { useContext } from 'react';
import { Banner } from '@airbus/components-react';

import { IError } from '../../../models/AppModels';
import { AppContext } from '../../../AppContext';

import { MESSAGES } from '../../../constants/Messages';

import '../../../i18n/config';
import './MessageBanner.css';

interface Props {
    message: string;
    type: 'info' | 'warning' | 'error' | 'success' | undefined;
}

const MessageBanner = ({ type, message }: Props) => {
    const { setMessageBanner } = useContext(AppContext);

    const defaultStateMessage: IError = {
        isBanner: false,
        message: '',
        type: undefined
    };

    const Message = () => {
        if (type === MESSAGES.ERROR.TYPE)
            return (
                <>
                    {' '}
                    <span className="title">{MESSAGES.ERROR.TITLE}</span>
                    <span className="description">
                        {i18n.t('messageBanner.error')} {message}
                    </span>
                </>
            );

        if (type === MESSAGES.SUCCESS.TYPE) return <span className="description">{message}</span>;

        if (type === MESSAGES.INFO.TYPE)
            return (
                <>
                    {' '}
                    <span>{MESSAGES.SUCCESS.TITLE}</span>
                    <span>
                        <br />
                        {message}
                    </span>
                </>
            );
        return null;
    };

    return (
        <Banner
            className="banner"
            autoHideDuration={type === MESSAGES.ERROR.TYPE ? undefined : 3000}
            variant={type}
            onClose={() => setMessageBanner(defaultStateMessage)}>
            <Message />
        </Banner>
    );
};

export default MessageBanner;
