import React from 'react';
import { IconButton } from '@airbus/components-react';
import { Close } from '@airbus/icons/react';

import classes from './CompanyTitle.module.css';

type Props = {
    handleClose: () => void;
};

const CompanyTitle = ({ handleClose }: Props) => {
    return (
        <div className={classes.container}>
            <div className={classes.left}>Company</div>
            <div className={classes.right} onClick={handleClose}>
                <IconButton size="small" variant="ghost">
                    <Close onClick={handleClose} />
                </IconButton>
            </div>
        </div>
    );
};

export default CompanyTitle;
