import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { PostAdd } from '@airbus/icons/react';
import { Typography } from '@airbus/components-react';

import DataTable from './DataTable';
import LoginSpinner from '../../../loaders/LoginSpinner';
import styles from './DeploymentsTable.module.css';
import { AircraftDeliveryBundle } from '../../../../models/AircraftDeliveryBundle';

type Props = {
    handleRefreshAppList?: () => void;
    apps?: AircraftDeliveryBundle[] | undefined;
};

const DeploymentsTable = (props: Props) => {
    const [data, setData] = useState<AircraftDeliveryBundle[] | undefined>([]);
    useEffect(() => {
        setData(props.apps);
    }, [props.apps]);

    if (!data) {
        return (
            <div className={styles.deployments_table}>
                <LoginSpinner fullPage={false} putLoginLabel={false} />
            </div>
        );
    } else if (data && data?.length > 0) {
        return <DataTable apps={data} />;
    } else {
        return (
            <div className={styles.content_deployments}>
                <PostAdd />
                <Typography variant="large" id="title">
                    {i18n.t('aircraft.aircraftConfig.deploymenthistory.noApps.title')}
                </Typography>
                <Typography variant="small" id="content">
                    {i18n.t('aircraft.aircraftConfig.deploymenthistory.noApps.content')}
                </Typography>
            </div>
        );
    }
};

export default DeploymentsTable;
