import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthService } from 'react-oauth2-pkce';
import { Button, ColorModeProvider, Typography } from '@airbus/components-react';
import i18n from 'i18next';

import { selectToken, selectTokenExpiryDate, setCookie, setToken, setTokenExpiryDate } from '../../redux/app/features/credentialsSlice';

import './Login.css';
import '../../i18n/config';

type Props = {
    authService: AuthService;
};

const Login = ({ authService }: Props) => {
    const [mustLog, setMustLog] = useState<boolean>(false);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const dispatch = useDispatch();

    useEffect(() => {
        if (token === undefined && tokenExpiryDate === undefined && mustLog) {
            authService
                .login()
                .then(() => {
                    if (authService.isAuthenticated()) {
                        const authToken = authService.getAuthTokens();
                        dispatch(setToken(authToken.id_token));
                        dispatch(setTokenExpiryDate(authToken.expires_at || 0));
                        if (token !== undefined && tokenExpiryDate !== undefined) {
                            dispatch(
                                setCookie({
                                    token,
                                    expiryDate: new Date(tokenExpiryDate)
                                })
                            );
                        }
                        setMustLog(false);
                    }
                })
                .catch(() => {
                    console.error('Error while login');
                });
        }
    }, [mustLog, token, tokenExpiryDate]);

    return (
        <div>
            <ColorModeProvider mode="dark">
                <div className="bg-primaryblue-90 p-6 flex-container">
                    <div className="text-left flex-item">
                        <Typography variant="h1">{i18n.t('login.title')}</Typography>
                        <Typography variant="small">
                            {i18n.t('login.content1')}
                            <br />
                            <br />
                        </Typography>
                        <span
                            onClick={() => {
                                setMustLog(true);
                            }}>
                            <Button variant="primary">{i18n.t('login.button')}</Button>
                        </span>
                    </div>
                    <div className="text-left flex-item">{/*TODO: ADD IMAGE */}</div>
                </div>
            </ColorModeProvider>
            <div className="login-content">
                <Typography variant="h4">{i18n.t('login.subtitle')}</Typography>
                <Typography variant="small">{i18n.t('login.subContent1')}</Typography>
            </div>
        </div>
    );
};

export default Login;
