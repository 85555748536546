import React, { useEffect, useState } from 'react';
import { ColorModeProvider, Typography } from '@airbus/components-react';
import { Box, Tab, Tabs } from '@mui/material';
import i18n from 'i18next';
import { Link, useHistory } from 'react-router-dom';

import History from './history/History';
import Schedule from './schedule/Schedule';
import LoadingSpinner from '../loaders/LoginSpinner';

import classes from './Deployment.module.css';

const Deployment = () => {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<'schedule' | 'history' | undefined>();

    useEffect(() => {
        switch (window.location.pathname) {
            case '/deployment/history':
                history.push({
                    pathname: '/deployment/history',
                    state: { roleId: null, roleName: null }
                });
                setSelectedTab('history');
                break;
            case '/deployment':
            case '/deployment/schedule':
            default:
                history.push({
                    pathname: '/deployment/schedule',
                    state: { roleId: null, roleName: null }
                });
                setSelectedTab('schedule');
                break;
        }
    }, []);

    return (
        <>
            {selectedTab === undefined ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : (
                <div className={classes.deployment_container}>
                    <ColorModeProvider mode="dark">
                        <div className="bg-primaryblue-90 main-page-title">
                            <div className="text-left">
                                <Typography variant="h2">{i18n.t('deployment.title')}</Typography>
                            </div>
                        </div>
                    </ColorModeProvider>
                    <div className={classes.deployment_content_container}>
                        <Box sx={{ flexGrow: 1, flex: '0 0' }}>
                            <Tabs
                                sx={{
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    textAlign: 'left',
                                    width: '200px'
                                }}
                                orientation="vertical"
                                value={selectedTab}
                                onChange={(event, value) => {
                                    history.push({
                                        pathname: value,
                                        state: {
                                            roleId: null,
                                            roleName: null
                                        }
                                    });
                                    setSelectedTab(value);
                                }}>
                                <Tab
                                    label={`${i18n.t('deployment.history_tab')}`}
                                    component={Link}
                                    to={`/deployment/history`}
                                    value={'history'}
                                />
                                <Tab
                                    label={`${i18n.t('deployment.schedule_tab')}`}
                                    component={Link}
                                    to={`/deployment/schedule`}
                                    value={'schedule'}
                                />
                            </Tabs>
                        </Box>
                        {selectedTab === 'history' ? <History /> : <Schedule />}
                    </div>
                </div>
            )}
        </>
    );
};

export default Deployment;
